import React from 'react';
import styled from 'styled-components';

import Container from '../components/LayoutGrid/Container';
import ExpandableBox from '../components/ExpandableBox/ExpandableBox';

const qAContent = [
  {
    id: 'qa-1',
    intro: 'Change management is important to steer the digitalization process. How important is it to inspire the people and motivate them to use Industry 4.0 and digitalization?',
    full: '',
  },
  {
    id: 'qa-2',
    intro: 'What exactly is NEXEED and how does it fit in the Bosch portfolio?',
    full: '',
  },
  {
    id: 'qa-3',
    intro: 'How can we combine all the individual new software solutions, specially the OEM or TIER1 Industrial IIoT platforms?',
    full: '',
  },
  {
    id: 'qa-4',
    intro: 'Do you involve a UX team in your projects?',
    full: '',
  },
  {
    id: 'qa-5',
    intro: 'How far have we come on the path from the moment you created the vision of the factory of the future until today? What is missing most?',
    full: '',
  },
  {
    id: 'qa-6',
    intro: 'Are automated controls for equipment suited for better energy management, and is this something that Bosch investigated?',
    full: '',
  },
];

const qaContentRows = [];
qAContent.forEach((c, ci) => {
  if (ci%2 === 0) {
    qaContentRows.push([]);
  }
  qaContentRows[qaContentRows.length - 1].push(c);
});


const StyledRow = styled.div`
  box-sizing: border-box;
 color: #fff;
 display: flex;
 > div {

 }
`;

const StyledItemWrapper = styled.div`
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => { return theme.primaryColor; }};
  ${({ rowItemIndex }) => {
    const s = {};
    if (rowItemIndex === 0) {
      s.marginRight = '10px';
    } else if (rowItemIndex === 1) {
      s.marginLeft = '10px';
    }
    return s;
  }}
`;
const StyledItemInnerContent = styled.div`
  max-width: 540px;
  min-height: 200px;
  box-sizing: border-box;
  padding: 35px;
  font-weight: 300;
    letter-spacing: 2px;
  ${({ rowItemIndex }) => {
    const s = {};
    if (rowItemIndex === 0) {
      s.marginLeft = 'auto';
    } else if (rowItemIndex === 1) {
      s.marginRight = 'auto';
    }
    return s;
  }}
`;

const StyledOuterWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
`;
const StyledTitle = styled.h1`
  font-size: 60px;
  font-weight: 400;
  margin-top: 150px;
    margin-bottom: 90px;
`;
const QAPage = () => {
  return (
    <article>
    <StyledOuterWrapper>
    <Container>
      <StyledTitle>Q&A NEXEEDit Summit</StyledTitle>
    </Container>
    {
      qaContentRows.map((qas, rowIndex) => {
        return (
          <StyledRow key={`row-${rowIndex}`}>
         
            {
              qas.map((c, ci) => {
                const { id, intro, full } = c;
                return (
                    <StyledItemWrapper rowItemIndex={ci} key={id}>
                    <StyledItemInnerContent rowItemIndex={ci}>
                 
                    <ExpandableBox
      
                      intro={intro}
                    >
                      {full}
                    </ExpandableBox>
                    </StyledItemInnerContent>
                    </StyledItemWrapper>
                
                );
              })
            }
          
          </StyledRow>
        )
      })
    }
    </StyledOuterWrapper>
    </article>
  );
};

export default QAPage;