
 import React from 'react';

 import styled from 'styled-components';



 const StyledInput = styled.input`
  padding: 15px 30px;
  background-color: transparent;
  border: 0;
  outline: 0;
  &:focus,
  &:hover {
    outline: 0;
  }
  font-weight: 200;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.39px;
  letter-spacing: -0.77px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  ${({ error }) => {
    if (!error) return {};
    return {
      borderColor: 'red',
    };
  }}
 `;


 const Input = ({ field, form, ...props }) => {
   const { name } = field;
   const { touched, errors } = form;
  const isFailed = !!touched[name] && !!errors[name];
  return <StyledInput {...field} value={field.value || ''} {...props} error={isFailed}/>;
};


export default Input;