import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button/Button';
import Container from '../components/LayoutGrid/Container';

import useMenu from '../hooks/menu';

const StyledIntroWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  color: #fff;
  background-color: ${({ theme }) => { return theme.primaryColor; }};
  h1 {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 63px;
    margin: 0;
    margin-bottom: 25px;
    line-height: 1;
    margin-top: auto;
  }
  border-bottom: 60px solid #440C41;
 
`;

const StyledBottom = styled.div`
  margin-top: auto;
  img {
    width: 30px;
    height: auto;
    display: block;
    margin-top: 6px;
  }
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 600px;
`;


const IntroPage = () => {
  const [goToPage] = useMenu();
  return (
    <div>

    <StyledIntroWrapper>
      <Container>
        <StyledInner>
          <h1>
            Unblock<br />
            the future.
          </h1>
          <Button
            layout="simple"
            onClick={() => {
              goToPage('register');
            }}
          >
            Register here
          </Button>
          <StyledBottom>
            <br />
            <small>Scroll down to explore</small>
            <img src="img/arrow-down-frame.svg" />
          </StyledBottom>
        </StyledInner>
       
      </Container>
    </StyledIntroWrapper>


    </div>
  );
};

export default IntroPage;