import React from 'react';


import styled from 'styled-components';


const StyledWrapper = styled.div`
  margin: 0 auto;
  padding: 0 25px;
  max-width: ${({ fluid }) => { return fluid ? '100vw' : '1000px' }};
  ${({ flex, size }) => {
    const s = {};
    if (flex) {
      s.display = 'flex';
    }
    if (size === 'small') {
      s.maxWidth = '900px';
    }
    return s;
  }}
`;

const Container = (props) => {
  const { fluid, flex = false, size } = props;
  return (
    <StyledWrapper fluid={fluid} flex={flex} size={size}>{props.children}</StyledWrapper>
  );
};

export default Container;