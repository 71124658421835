import React from 'react';
import { createGlobalStyle } from 'styled-components';

import ThemeProvider from './theme/Provider';

import MainLayout from './layouts/Main';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Bosch";
    font-weight: normal;
    src: url("assets/fonts/BoschSans-Regular.otf") format("opentype");
  }
  @font-face {
    font-family: "Bosch";
    font-weight: normal;
    font-style: italic;
    src: url("assets/fonts/BoschSans-RegularItalic.otf") format("opentype");
  }
  @font-face {
    font-family: "Bosch";
    font-weight: 200;
    src: url("assets/fonts/BoschSans-Light.otf") format("opentype");
  }
  @font-face {
    font-family: "Bosch";
    font-weight: bold;
    src: url("assets/fonts/BoschSans-Bold.otf") format("opentype");
  }
  ::placeholder {
    color: #ffffff;
    opacity: 0.35;
  }
  :-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.35;
  }
  ::-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.35;
  }
  .css-1wa3eu0-placeholder {
    color: #ffffff !important;
    opacity: 0.35;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: "Bosch";
    color: ${({ textColor }) => { return textColor; }};
    overflow-x: hidden;
  }

  p,
  label {
    font-size: 16px;
    letter-spacing: -0.69px;
    line-height: 25px;
    margin-bottom: 30px;
  }
 
`

const App = () => {
  return (
    <>
      <ThemeProvider>
        <GlobalStyle />
        <MainLayout />
      </ThemeProvider>
    </>
  );
};




export default App;