import * as Yup from 'yup';


/*
  'email' => 'info@300pl.us',
  'phone' => '+49111111111',
  'name' => 'Rütten',
  'firstname' => 'Timo',
  'country' => 'DE',
  'formOfAddress' => '0002',
  'function' => 'ZB01',
  'company' => '300plus',
  'topic' => '177',
  'projectStatus' => '111',
  'acceptedLegal' => true,
  'contactConsent' => true,
*/

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  phone: Yup.string().required(),
  acceptedLegal: Yup.bool().default(false).oneOf([true], 'Field must be checked').required(),
  contactConsent: Yup.bool().default(false),


  name: Yup.string().required(),
  firstname: Yup.string().required(),
  country: Yup.string().required(),
  formOfAddress: Yup.string().required(),
  function: Yup.string().required(),
  topic: Yup.string().required(),
  company: Yup.string().required(),
  projectStatus: Yup.string().required(),
  /*
    

  'name' => 'Rütten',
  'firstname' => 'Timo',
  'country' => 'DE',
  'formOfAddress' => '0002',
  'function' => 'ZB01',
  'company' => '300plus',
  'topic' => '177',
  'projectStatus' => '111',


  */
  /*
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  */
});

export default RegisterSchema;