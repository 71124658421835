import React from 'react';

import styled from 'styled-components';


const StyledButton = styled.button`
  font-size: 36px;
  line-height: 2;
  font-weight: normal;
  padding: 0 100px;
  background-color: ${({ theme, layout }) => {
    if (layout === 'simple') return '#ffffff';
    return theme.primaryColor;
  }};
  color: ${({ theme, layout }) => {
    if (layout === 'simple') return theme.primaryColor;
    return '#ffffff';
  }};

  border: 0;
  outline: 0;
  cursor: pointer;
  &:hover,
  &:focus {
    border: 0;
    outline: 0;
  }
  &:disabled {
    pointer-events: none;
    color: #ffffff;
    background-color: #eff1f2;
  }
  ${({ layout }) => {
    const s = {};
    if (layout === 'simple') {
      s.fontSize = '16px';
      s.padding = '4px 15px';
    }
    return s;
  }}

`;


const Button = (props) => {
  return (
    <StyledButton type="button" {...props}>{props.children}</StyledButton>
  );
};


export default Button;