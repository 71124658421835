import React, { useEffect, useRef, useState, useCallback } from 'react';

import styled from 'styled-components';

import Container from '../LayoutGrid/Container';

const StyledLogoWrapper = styled.div`
  position: relative;
  img {
    width: 240px;
    height: auto;
    position: relative;
    z-index: 1;


  }
`;


const FixedWrapper = styled.div`
    z-index: 5;
  ${({ isFixed }) => {
    const s = {
      position: 'absolute',
      top: '115px',
    };
    if (isFixed) {
      s.position = 'fixed';
      s.top = '6px';
    }
    return s;
  }}
`;
const ColorStripeTop = styled.div`
position: absolute;
    top: -5px;
    left: 0;
    width: 100%;
    height: 44px;
    background-color: #671861;

    z-index: 0;
`;
const ColorStripeBottom = styled.div`
position: absolute;
top: 39px;
    left: 0;
    width: 100%;
    height: 38px;
    z-index: 0;
  background-color: #9E2896;
  padding-right: 100px;
`;

const Logo = () => {
  const logoTopBorderRef = useRef(null);
  const [isFixed, setIsFixed] = useState(false);
 
  useEffect(() => {
    if (!logoTopBorderRef.current) return;
    const rect = logoTopBorderRef.current.getBoundingClientRect();
    // const { y } = rect;
    const y = rect.y + document.body.scrollTop;
    console.log({ y });
    const setFixedOrNot = () => {
      const { scrollTop } = document.body;
      console.log({ scrollTop, y });
      if (scrollTop >= y) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };
    document.addEventListener('scroll', setFixedOrNot);
    setFixedOrNot();
  }, [logoTopBorderRef])
  console.log({ isFixed });
  return (
    <Container>
      <StyledLogoWrapper>
        <FixedWrapper isFixed={isFixed}>
          <ColorStripeTop ref={logoTopBorderRef} />
          <ColorStripeBottom />
          <img src="img/NEXEEDit_Summit_21_Logo_w.svg" />
        </FixedWrapper>
        
      </StyledLogoWrapper>
    </Container>
    
  );
};

export default Logo;