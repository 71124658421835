import React, { useState } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import countriesList from 'countries-list';

import Button from '../components/Button/Button';
import Container from '../components/LayoutGrid/Container';
import Form from '../components/Form/Form';
import registerSchema from '../schemas/register';
import Section from '../components/Section/Section';

import Input from '../components/Form/Inputs/Input';
import Select from '../components/Form/Inputs/Select';
import Checkbox from '../components/Form/Inputs/Checkbox';

import Title from '../components/Title/Title';

import { device } from '../theme/styles';


const selectOptions = {
  formOfAddress: {
    options: [
      {
        value: '0002',
        label: 'Mr.',
      },
      {
        value: '0001',
        label: 'Mrs.',
      }
    ],
  },
  projectStatus: {
    options: [
      {
        value: '101',
        label: "I don't have a project yet and would like some information",
      },
      {
        value: '111',
        label: 'I am planning a project and have some questions',
      },
      {
        value: '121',
        label: 'I already have a project and need support',
      },
    ],
  },
  function: {
    options: [
      {
        value: 'ZB01',
        label: 'Others',
      },
      {
        value: 'ZB02',
        label: 'I influence important decisions',
      },
      {
        value: 'ZB03',
        label: 'I use the products myself (in the future)',
      },
      {
        value: 'ZB04',
        label: 'I make important decisions',
      },

    ],
  },
  topic: {
    options: [
      {
        value: '137',
        label: 'Other',
      },
      {
        value: '327',
        label: 'Shopfloor Management',
      },
      {
        value: '567',
        label: 'Quality Management',
      },
      {
        value: '577',
        label: 'Maintenance Management',
      },
      {
        value: '587',
        label: 'Real-time Logistics',
      },
      {
        value: '597',
        label: 'Control technology',
      },
      {
        value: '607',
        label: 'Partner program',
      },
      {
        value: '617',
        label: 'Consulting',
      },
    ],
  }
};


const StyledWrapper = styled.div`
  background-color: ${({ theme }) => { return theme.primaryColor; }};
  color: #ffffff;
  padding: 40px 0;

`;

const TitlesWrapper = styled.div`
  h1 {
    color: #18837e;
    font-size: 49px;
  }
  h2 {
    color: #000000;
    font-size: 36px;
    margin-bottom: 130px;
    margin-top: 30px;
  }
  @media ${device.tablet} {
    padding-top: 40px;
    background-color: ${({ theme }) => { return theme.primaryColor; }};
    h1,
    h2 {
      margin-bottom: 0;
      color: #fff;
      margin-top: 0;
    }
    h1 {
      margin-top: 75px;
      padding-bottom: 30px;
    }
  }
`;
const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -25px -50px;
  > div {
    flex: 0 0 100%;
    width: 100%;
    @media ${device.tablet} {
      flex: 0 0 50%;
      width: 50%;
    }
    > div {
      box-sizing: border-box;
    padding: 25px 50px;
    }

  }
`;

/*
  'email' => 'info@300pl.us',
  'phone' => '+49111111111',
  'name' => 'Rütten',
  'firstname' => 'Timo',
  'country' => 'DE',
  'formOfAddress' => '0002',
  'function' => 'ZB01',
  'company' => '300plus',
  'topic' => '177',
  'projectStatus' => '111',
  // checkbox 1
  // checbox 2
*/

const formStructure = [
  [
    {
      name: 'formOfAddress',
      caption: 'Title',
      component: Select,
      options: selectOptions.formOfAddress.options,
    },
    {
      name: 'name',
      caption: 'Name',
    },
    {
      name: 'firstname',
      caption: 'Firstname',
    },
    {
      name: 'company',
      caption: 'Company name',
    },
    {
      name: 'email',
      caption: 'Business E-mail',
    },
  ],
  [
    {
      name: 'phone',
      caption: 'Business phone number',
    },
  
    {
      name: 'country',
      caption: 'Country',
      component: Select,
      isSearchable: true,
      options: Object.keys(countriesList.countries).map((countryCode) => {
        return {
          value: countryCode,
          label: countriesList.countries[countryCode].name
        };
      })
    },
  
    {
      name: 'function',
      caption: 'Your role in your company',
      component: Select,
      options: selectOptions.function.options,
    },
  
    {
      name: 'topic',
      caption: 'Which topic are you interested in',
      component: Select,
      options: selectOptions.topic.options,
    },
  
    {
      name: 'projectStatus',
      caption: 'Your project status',
      component: Select,
      options: selectOptions.projectStatus.options,
    },
  ],

  
];

const IndentedContentWrapper = styled.div`
  padding-left: 28px;
`;
const StyledSubmitButtonWrapper = styled.div`
  text-align: center;
`;
const StyledInfo = styled.p`
  color: ${({ theme }) => { return theme.primaryColor; }};
  display: ${({ hideOnMobile }) => { return hideOnMobile ? 'none' : 'block' }};
  @media ${device.tablet} {
    display: ${({ showOnMobile, hideOnMobile }) => {
      if (showOnMobile) return 'none';
      if (hideOnMobile) return 'block';
      return 'block';
    }};
  }
`;

const BorderTop = styled.div`
background-color:#440C41;
height: 60px;
`;

const RegisterPage = () => {
  const [formSuccess, setFormSuccess] = useState(false);
  return (
    <article id="page-register">
    <BorderTop></BorderTop>
      <Form
        validateOnMount
        initialValues={registerSchema.cast({})}
        validationSchema={registerSchema}
        onSubmit={async (values) => {
          // same shape as initial values
 
          const response = await fetch('/form/request.php', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(values)
          });
          const registerResponse = await response.json();
          console.log({ registerResponse });
          if (registerResponse && registerResponse.success) {
            setFormSuccess(true);
          }
        }}
        >
            {({ values, errors, touched, isSubmitting }) => {
         
              const formDisabled = !!errors && Object.keys(errors).length;
              return (
                <>
                <TitlesWrapper>
                  <Container size="small">
                    <Title as="h2" size="medium" mb={7}>Nexeedit<br />Summit 20211</Title>
                    <Title as="h1" size="large" mb={4}>Register here.</Title>
                  
                  </Container>
                </TitlesWrapper>
                <Container size="small">
                <StyledInfo showOnMobile>
                  *Please fill out all mandatory fields.
                </StyledInfo>
                </Container>
            
                
                <StyledWrapper>
      <Container size="small">
       

          <FormInputWrapper>
            {
              formStructure.map((inputs, i) => {
                return (
                  <div key={`form-row-${i}`}>
                    {
                      inputs.map((input) => {
                        const {
                          name,
                          component = Input,
                          caption,
                        } = input;
                        return (
                          <div
                            key={name}
                          >
                            <Field {...input} component={component} placeholder={caption ? `*${caption}` : undefined}/>
                          </div>
                        );
                      })
                    }
                  </div>
                );
               
                
              })
            }
          </FormInputWrapper>


         
      </Container>
        
      </StyledWrapper>
      <Container size="small">
      <Section>
        <div>
          <Field  
            component={Checkbox}
            name="acceptedLegal"
            label="*I have read the GTC and the Data protection notice of Robert Bosch Manufacturing Solutions GmbH."
          />
        </div>
        <div>
          <Field  
            component={Checkbox}
            name="contactConsent"
            label={(
              <>
              Yes, I consent to being contacted via email/telephone by Robert Bosch Manufacturing Solutions GmbH, Wernerstraße 51, 70469 Stuttgart, Germany, for information regarding their Bosch Connected Industry products and services (e.g. webinar dates, white paper downloads, client satisfaction surveys).
              </>
            )}
          />
        </div>
        <IndentedContentWrapper>
      <p>
        Note: You may withdraw the above declarations of consent at any time for the future by sending a message (in any format) to Robert Bosch Manufacturing Solutions GmbH, Wernerstraße 51, 70469 Stuttgart Germany or by email to privacy.BCI@de.bosch.com. For email newsletters, we also offer you the option to withdraw  your consent via a link built into the newsletter.
        </p>
        <p>
        Email with a confirmation link:<br />
If you have given your consent to be contacted via email/telephone, you will shortly receive an email with a confirmation link. Please click on this link to verify your entries.

        </p>
        <StyledInfo hideOnMobile>
        *Please fill out all mandatory fields.
        </StyledInfo>

  
      </IndentedContentWrapper>
      <StyledSubmitButtonWrapper>
      
      <Button type="submit" disabled={formDisabled || !!formSuccess || isSubmitting}>
        {
          !formSuccess && 'Confirm'
        }
        {
          !!formSuccess && 'Form sent'
        }
      </Button>
      </StyledSubmitButtonWrapper>
     
      </Section>
    
     
      </Container>

              </>
              );
            }}
          </Form>
    
      
    </article>
  );
};

export default RegisterPage;