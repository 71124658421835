import React from 'react';


import styled from 'styled-components';

const StyledSection = styled.div`
  padding: 55px 0;
`;

const Section = (props) => {
  return (
    <StyledSection>
      {props.children}
    </StyledSection>
  );
};

export default Section;