import find from 'lodash.find';
import get from 'lodash.get';
import React, { useState } from 'react';
import ReactSelect from 'react-select';





import styled from 'styled-components';

import { device } from '../../../theme/styles';


const StyledSelectArrowSvg = styled.svg`
  transform: ${({ menuIsOpen }) => { return menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'}};
`;


const SelectArrow = (props) => {
  return (

<StyledSelectArrowSvg width="25px" height="13px" viewBox="0 0 25 13" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Registrierungsformular_1" transform="translate(-1102.000000, -551.000000)" stroke="#FFFFFF" strokeWidth="1.3">
            <path d="M1125.92766,551.562113 L1115.17287,562.316978 C1114.84595,562.643825 1114.31586,562.643825 1113.98893,562.316978 L1103.26503,551.592995" id="Stroke-1-Copy-3"></path>
        </g>
    </g>
</StyledSelectArrowSvg>
  );
};

const StyledInput = styled.div`
  position: relative;
  > svg {
    position: absolute;
    bottom: 20px;
    right: 10px;
    transition: transform 0.19s ease-out;
  }
  .css-tlfecz-indicatorContainer,
  .css-1hb7zxy-indicatorsContainer {
    display: none !important;
  }
  .css-1uccc91-singleValue {
    height: 100%;
    width: 100%;

    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;

    transform: none;
    top: auto;
    bottom: 0;
    color: #ffffff;
    line-height: 1;
  }
  .css-b8ldur-Input {
    color: #ffffff;
  }
  .css-yk16xz-control,
  .css-1pahdxg-control {
    box-shadow: 0 0 0;
    padding: 11px 30px;
    background-color: transparent;
    border: 0;
    outline: 0;
    &:focus,
    &:hover {
      outline: 0;
    }
    font-weight: 200;
    border-radius: 0;
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
    font-size: 18px;
    line-height: 1.39px;
    letter-spacing: -0.77px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    &:hover,
    &:focus {
      border-color: #ffffff;
      box-shadow: 0 0 0;
    }
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-yk16xz-control {

  }
  .css-26l3qy-menu {
    width: 100%;
    border-radius: 0;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.31);
    border-width: 0;
    border: 0;
    @media ${device.tablet} {
      width: auto;
      min-width: 230px;
    }
  }
  .css-9gakcf-option {}
  .css-yt9ioa-option {
    background-color: #ffffff;
    color: #000000;
    
  }
  .css-9gakcf-option,
  .css-9gakcf-option,
  .css-1n7v3ny-option {
    color: #fff;
    background-color: ${({ theme }) => { return theme.primaryColor; }};
    &:active {
      background-color: ${({ theme }) => { return theme.primaryColor; }};
    }
  }
  .css-yt9ioa-option,
  .css-9gakcf-option,
  .css-1n7v3ny-option {
    white-space: nowrap;
    cursor: pointer;
  }
 
 `;


 const Select = (props) => {
  const {
    field,
    form,
    options,
    placeholder,
    isSearchable = false,
    isMulti,
    ...otherProps
  } = props;
  const selectedValue = find(options, { value: field.valie });
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <StyledInput>
 <ReactSelect
      onMenuOpen={() => {
        setMenuIsOpen(true);
      }}
      onMenuClose={() => {
        setMenuIsOpen(false);
      }}
      value={selectedValue}
      onChange={(val) => {
       // field.setValue();
       console.log({ val });
       if (typeof val !== 'undefined') {
        form.setFieldValue(field.name, val.value);
       } else {
        form.setFieldValue(field.name, undefined);
       }
      }}
      options={options}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isMulti={isMulti}
    />
    <SelectArrow menuIsOpen={menuIsOpen} />
    </StyledInput>
   
  );
  //return <StyledInput {...field} {...otherProps} />;
};


export default Select;