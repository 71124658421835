import React from 'react';


const ExpandableBox = (props = {}) => {
  const { intro, children } = props;
  return (
    <div>
      {
        intro
      }
      {
        children
      }
    </div>
  );
};

export default ExpandableBox;