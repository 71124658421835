import React from 'react';
import styled from 'styled-components';




const Title = (props) => {
  const { as: Component = 'h1', children, className, size = 'default' } = props;
  return (
    <Component className={className}>{children}</Component>
  );
};

const StyledTitle = styled(Title)`
  font-size: 25px;
  margin: 0;
  line-height: 1;
  font-weight: normal;
  ${({ size, mt, mb }) => {
    const s = {};
    if (mt) {
      s.marginTop = mt * 10;
    }
    if (mb) {
      s.marginBottom = mb * 10;
    }
    if (size === 'medium') {
      s.fontSize = '50px';
      s.letterSpacing = '-2.14px';

    } else if (size === 'large') {
      s.fontSize = '90';
      s.letterSpacing = '-0.84px';
 
      // s.fontWeight = 'bold';
    }
    return s;
  }}
`;

export default StyledTitle;