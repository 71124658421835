import React from 'react';
import styled from 'styled-components';

import RegisterPage from '../pages/Register';
import IntroPage from '../pages/Intro';
import ReviewPage from '../pages/Reviews';
import SpeakersPage from '../pages/Speakers';
import QAPage from '../pages/QA';

import Logo from '../components/Logo/Logo';

import Container from '../components/LayoutGrid/Container';

import ColoredStripe from '../components/ColoredStripe/ColoredStripe';


const HeaderWrapper = styled.div`
  padding: 20px 0; 
  img {
    height: 60px;
    width: auto;
    display: inline-block;
  }
`;
const Wrapper = styled.div`
  padding-bottom: 50px;
`;

const MainLayout = () => {
  return (
    <Wrapper>
     <ColoredStripe />
      <Logo />
      <HeaderWrapper>
      <Container>
      <img src="img/logo.png" />
      </Container>
          </HeaderWrapper>
          <IntroPage></IntroPage>
          <ReviewPage></ReviewPage>
          <SpeakersPage></SpeakersPage>
          <QAPage></QAPage>
          <RegisterPage />
      {
        /*
         
  
    
     
  
  
        */
      }
   
    
    </Wrapper>
  );
};


export default MainLayout;