
 import React from 'react';

 import styled from 'styled-components';



 const StyledInput = styled.input`

  background-color: transparent;
  border: 0;
  outline: 0;
  &:focus,
  &:hover {
    outline: 0;
  }
  font-weight: 200;
  border-bottom: 1px solid #ffffff;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.39px;
  letter-spacing: -0.77px;

  box-sizing: border-box;

  width: 20px;
  height: 20px;
  flex: 0 0 20px;
  margin: 0;
  margin-right: 8px;
 
 `;

 const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  label {
    top: -5px;
    position: relative;
  }
 `;


 const Checkbox = (props) => {
  const {
    field,
    form,
    label,
    ...otherProps
  } = props;
  const inputId = `checkbox-${field.name}`;
  return (
    <StyledWrapper>

      <StyledInput {...field} {...otherProps} type="checkbox" id={inputId} />
      <label htmlFor={inputId}>{label}</label>
    </StyledWrapper>
  );
};


export default Checkbox;