import React from 'react';
import styled from 'styled-components';

import Container from '../components/LayoutGrid/Container';

const StyledBox = styled.div`
  margin-top: 100px;
  margin-bottom: 60px;
  padding: 30px;
  padding-bottom: 90px;
  background-color: ${({ theme }) => { return theme.primaryColor; }};
  color: #fff;  
  h1 {
    margin: 0;
    font-weight: 400;
    font-size: 50px;
    line-height: 1;
  }
`;

const StyledTexts = styled.div`
  display: flex;
  flex-direction: row;
  > div {
    flex: 0 0 50%;
  }
  h3 {
    font-weight: 400;
    font-size: 28px;
    margin: 0;
  }
`;

const ReviewPage = () => {
  return (
    <article>
      <Container>
      <StyledBox>
        <h1>
          Review 2020:<br />
          Last time was amazing!<br />
          Lorem ipsum success
        </h1>
      </StyledBox>
      <StyledTexts>
        <div>
          <h3>+600</h3>
          <p>partipicians last year Lorem ipsum dolor sit amet, coset sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore. nonumy eirmod tempor invidunt ut labore dolore.</p>
        </div>
        <div>
        <h3>Decision makers</h3>
          <p>partipicians last year Lorem ipsum dolor sit amet, coset sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore dolore. nonumy eirmod tempor invidunt ut labore dolore.</p>
        </div>
      </StyledTexts>
      </Container>
     

    </article>
  );
};

export default ReviewPage;