import React from 'react';

import { ThemeProvider } from 'styled-components';


const theme = {
  primaryColor: '#671761',
  textColor: '#000000',
  textOnPrimary: '#ffffff',
};

const Provider = (props) => {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};


export default Provider;