import React from 'react';
import styled from 'styled-components';

import Container from '../components/LayoutGrid/Container';

const StyledSpeaker = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${({ theme }) => { return theme.primaryColor; }};
  padding-bottom: 40px;
  margin-bottom: 40px;
`;

const StyledPerson = styled.div`
  flex: 0 0 400px;
  img {
    width: 140px;
    height: auto;
    display: block;
    padding: 0 15px;
    border-bottom: 1px solid ${({ theme }) => { return theme.primaryColor; }};
  }
  ul {

    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 25px;
    font-size: 14px;
  }
`;
const StyledTitle = styled.h1`
  font-size: 60px;
  font-weight: 400;
  margin-top: 150px;
    margin-bottom: 90px;
`;
const StyledInfo = styled.div`

`;
const StyledSlogan = styled.span`
     font-weight: 400;
    font-size: 28px;
`;
const speakerContent = [
  {
    id: 'speaker-1',
    avatar: 'img/speaker_1.png',
    slogan: 'How Digitalization advances production even during the pandemic.',
    readmore: '', // lorem ipsum
    infoRows: [
      {
        id: 'info-1-1',
        text: 'Dr. Former Speaker 1',
      },
      {
        id: 'info-1-2',
        text: 'Director at Institute of Production Science (wbk)',
      },
      {
        id: 'info-1-3',
        text: 'Karlsruhe Institute of Technology (KIT)  Karlsruhe',
      },
      {
        id: 'info-1-4',
        text: 'Institute of Technology (KIT)',
      },
    ],
  },
  {
    id: 'speaker-2',
    avatar: 'img/speaker_2.png',
    slogan: 'Changeable, fully connected and intelligent production systems are key factors for new business models',
    readmore: '', // lorem ipsum
    infoRows: [
      {
        id: 'info-2-1',
        text: 'Dr. Former Speaker 2',
      },
      {
        id: 'info-2-2',
        text: 'Director at Institute of Production',
      },
      {
        id: 'info-2-3',
        text: 'Science (wbk), Karlsruhe Institute of',
      },
      {
        id: 'info-2-4',
        text: 'Technology (KIT)',
      },
    ],
  },
  {
    id: 'speaker-3',
    avatar: 'img/speaker_1.png',
    slogan: 'Changeable, fully connected and intelligent production systems are key factors for new business models',
    readmore: '', // lorem ipsum
    infoRows: [
      {
        id: 'info-3-1',
        text: 'Dr. Former Speaker 3',
      },
      {
        id: 'info-3-2',
        text: 'Director at Institute of Production',
      },
      {
        id: 'info-3-3',
        text: 'Science (wbk), Karlsruhe Institute of',
      },
      {
        id: 'info-3-4',
        text: 'Technology (KIT)',
      },
    ],
  },
];

const SpeakersPage = () => {
  return (
    <article>
    <Container>
    <StyledTitle>Last years top speakers.</StyledTitle>
    {
        speakerContent.map((speaker) => {
          const { id, avatar, slogan, readmore, infoRows = [] } = speaker;
          return (
            <StyledSpeaker key={id}>
              <StyledPerson>
                <img src={avatar} />
                <ul>
                  {
                    infoRows.map((info) => {
                      return (
                        <li key={info.id}>
                          {info.text}
                        </li>
                      );
                    })
                  }
                </ul>
              </StyledPerson>
              <StyledInfo>
                  <StyledSlogan>„{slogan}“</StyledSlogan>
               
              </StyledInfo>
            </StyledSpeaker>
          );
        })
      }
    </Container>
      
    </article>
  );
};

export default SpeakersPage;