import React from 'react';
import isFunction from 'lodash.isfunction';
import { Formik, Form } from 'formik';

const FormWrapper = (props) => {
  const { foo, children, ...formProps } = props;
  return (
    <Formik
      {...formProps}
    >
      {(formikData) => {
        return (
        <Form>
          {
            !!isFunction(children) ? children(formikData) : children
          }
        </Form>
        );
      }}
    </Formik>
  );
};


export default FormWrapper;